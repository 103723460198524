import React from 'react';

const ConditionItem = ({ title }) => {
  return (
    <div className="condition-item">
      <div className="condition-item-block">
        <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
          <path d="M19.8437 5.01015L7.41652 17.8243C7.30729 17.9368 7.15924 18 7.00487 18C6.8505 18 6.70244 17.9368 6.59322 17.8243L1.15634 12.2181C1.05345 12.1043 0.997428 11.9537 1.00009 11.798C1.00275 11.6424 1.06389 11.494 1.17062 11.3839C1.27734 11.2739 1.42133 11.2108 1.57225 11.2081C1.72316 11.2053 1.86921 11.2631 1.97964 11.3692L7.00487 16.5499L19.0204 4.16121C19.1308 4.05511 19.2768 3.99735 19.4278 4.00009C19.5787 4.00284 19.7227 4.06588 19.8294 4.17593C19.9361 4.28598 19.9972 4.43446 19.9999 4.59007C20.0026 4.74568 19.9466 4.89629 19.8437 5.01015Z" fill="white" />
        </svg>
        <span>{title}</span>
      </div>
    </div>
  )
}

export default ConditionItem;
